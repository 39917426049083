import {FC, ReactNode, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useQueryRequest} from './QueryRequestProvider'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState, QueryResponseContextProps,
  stringifyRequestQuery,
  Response,
} from '../../helpers'
import axios from 'axios';
import {UserModel} from "../../../types";

async function fetchData<T>(url: string, query: string): Promise<Response<T[]>> {
  const d = await axios
    .get(`${url}${url.indexOf('?') >= 0 ? '&' : '?'}${query}`);
  return d.data;
}

const QueryResponseContext = createResponseContext<UserModel>(initialQueryResponse);
const QueryResponseProvider: FC<{children?: ReactNode, url: string, queryList?: string}> = ({children, url, queryList}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${queryList ?? ''}-${query}`,
    () => {
      return fetchData(url, query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

function useQueryResponse<T>() {
  return useContext<QueryResponseContextProps<T>>(QueryResponseContext)
}

function useQueryResponseData<T>(){
  const {response} = useQueryResponse<T>()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
