const QUERIES = {
  AUTOMATION_LIST: 'automation-list',
  SCHEDULED_LIST: 'scheduled-list',
  TRANSACTION_LIST: 'transaction-list',
  CONTRIBUTION_LIST: 'contribution-list',
  INVITATION_LIST: 'invitations-list',
  PRODUCT_LIST: 'product-list',
  CUSTOMER_LIST: 'customer-list',
  PAYMENT_LINK_LIST: 'payment-link-list',
  BULK_LINK_LIST: 'bulk-link-list',
  SESSIONS_LIST: 'session-list',
  SCHEDULED_TRANSACTION_LIST: 'scheduled-transaction-list',
  WALLET_TRANSACTION_LIST: 'wallet-transaction-list',
  WALLET_LIST: 'wallet-list',
}

export {QUERIES}
