import clsx from 'clsx'
import {Row} from 'react-table'

export interface CustomRowProps<T extends object> {
  row: Row<T>;
}

function CustomRow<T extends object>({row}: CustomRowProps<T>) {
  const {key, ...props} = row.getRowProps()
  return (
    <tr key={key} data-cy={key} {...props}>
      {row.cells.map((cell) => {
        const {key, ...propss} = cell.getCellProps()
        return (
          <td
            key={key}
            {...propss}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            data-cy={`cell-${cell.column.id}`}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export {CustomRow}
